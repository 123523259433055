import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { Lang } from "../types";

function TraductionChoice() {
  const [lang, setLang] = useState<Lang>("french");

  const [cookies, setCookie] = useCookies(["traduction-lang"]);

  const setTraductionLang = (lang: Lang) => {
    setLang(lang);
    setCookie("traduction-lang", lang, { maxAge: 3600, path: "/" });
  };

  useEffect(() => {
    if (cookies["traduction-lang"]) {
      setLang(cookies["traduction-lang"]);
    }
  }, [cookies]);

  return (
    <div className="d-flex column-gap-2" style={{ width: "fit-content" }}>
      <button className="active btn p-0 border-0" style={{ opacity: lang === "french" ? "100%" : "35%" }} onClick={() => setTraductionLang("french")}>
        <img src="img/franceFlag.webp" width="32" alt="drapeau de france" />
      </button>
      <button className="active btn p-0 border-0" style={{ opacity: lang === "english" ? "100%" : "35%" }} onClick={() => setTraductionLang("english")}>
        <img src="img/englandFlag.webp" width="32" alt="drapeau de grande bretagne" />
      </button>
      <button className="active btn p-0 border-0" style={{ opacity: lang === "italian" ? "100%" : "35%" }} onClick={() => setTraductionLang("italian")}>
        <img src="img/italyFlag.webp" width="32" alt="drapeau d'italie" />
      </button>
      <button className="active btn p-0 border-0" style={{ opacity: lang === "spanish" ? "100%" : "35%" }} onClick={() => setTraductionLang("spanish")}>
        <img src="img/spainFlag.webp" width="32" alt="drapeau d'espagne" />
      </button>
      <button className="active btn p-0 border-0" style={{ opacity: lang === "german" ? "100%" : "35%" }} onClick={() => setTraductionLang("german")}>
        <img src="img/germanyFlag.webp" width="32" alt="drapeau de l'allemagne" />
      </button>
    </div>
  );
}

export default TraductionChoice;
