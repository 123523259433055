import { useMutation, useQuery } from "@tanstack/react-query";
import { Link, generatePath, useLocation, useParams } from "react-router-dom";
import { ApiError, useAPI, useDocumentTitle, useUtils } from "../hooks";
import {
  EditionPhotoProblemType,
  EditionTextProblemType,
  Pagination,
  Photo,
  PhotoConsultForm,
  PhotoPlaceholder,
  PhotoProblem,
  PhotoProblemConsultForm,
  PhotoProblemPlaceholder,
  TextProblem,
  TextProblemConsultForm,
  TextProblemPlaceholder,
} from "../components";
import { useAuth } from "../contexts/auth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDoubleRight, faFilePdf, faListCheck, faRotate, faShuffle, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useMemo, useState } from "react";
import * as Routes from "../routes";
import { ModalTypeEnum, ProblemTypeEnum } from "../Utils";
import { useModals } from "../contexts/modals";
import {
  EditionBasketProblem,
  EditionType,
  MutationData,
  PhotoConsultFormValues,
  PhotoProblemConsultFormValues,
  PhotoProblemType,
  ProblemConsultFormValues,
  ProblemType,
  TextProblemConsultFormValues,
  TextProblemType,
} from "../types";
import { ProblemsTypeFiltersPageKey } from "./query";
import { Alert, Offcanvas, OverlayTrigger, Tooltip } from "react-bootstrap";
import TraductionChoice from "../components/TraductionChoice";

type ConsultProps = {
  isEdition?: boolean;
};

type ParamsType = {
  type: ProblemTypeEnum;
  page: string;
};

type QueryData = {
  page: number;
  totalPage: number;
  totalProblems: number;
  problems: ProblemType[];
  canSeePublicProblem: boolean;
};

function Consult({ isEdition = false }: Readonly<ConsultProps>) {
  const { type, page } = useParams<ParamsType>() as ParamsType;

  const { pathname } = useLocation();
  const regexPathname = /^(.*\/)\d+$/gi.exec(pathname);

  const { openModal } = useModals();

  const { user, isInitialLoading } = useAuth();
  const api = useAPI();
  const [showFilter, setShowFilter] = useState(false);
  const [showBasket, setShowBasket] = useState(false);
  const [filters, setFilters] = useState<ProblemConsultFormValues | null>(null);
  const { transFromDiscr } = useUtils();
  const [editionType, setEditionType] = useState<EditionType | null>(null);
  const [showAlert, setShowAlert] = useState(true);

  const { isLoading, isError, error, data } = useQuery<QueryData, ApiError>(
    ProblemsTypeFiltersPageKey(filters?.problemType as ProblemTypeEnum, filters as ProblemConsultFormValues, Number.parseInt(page)),
    () => api.problem.getProblems(filters?.problemType as ProblemTypeEnum, Number.parseInt(page), filters),
    {
      staleTime: 60 * 1000, // 1 min
      enabled: !!filters && !(isEdition && !!!editionType),
    }
  );

  const defaultFilters = useMemo<ProblemConsultFormValues>(() => {
    if (type === ProblemTypeEnum.TextProblem || type === ProblemTypeEnum.PhotoProblem) {
      return {
        tag: user ? ["COMMUNITY_PRIVATE", "COMMUNITY_PUBLIC"] : [],
        type: [],
        nivel: [],
        textResearch: "",
        responseState: "all",
        isEdition: isEdition,
        problemType: type,
      };
    } else {
      return {
        tag: user ? ["COMMUNITY_PRIVATE", "COMMUNITY_PUBLIC"] : [],
        type: [],
        problemType: type,
      };
    }
  }, [user, isEdition, type]);

  useEffect(() => {
    if (!isInitialLoading) {
      setFilters(defaultFilters);
    }
  }, [isInitialLoading, defaultFilters, type]);

  const [title, setTitle] = useState<string>("");

  useDocumentTitle(title);

  useEffect(() => {
    setShowAlert(true);

    if (type === ProblemTypeEnum.TextProblem) setTitle("Des milliers de problèmes mathématiques avec un accès par filtres");
    if (type === ProblemTypeEnum.Photo) setTitle("Des milliers de photos mathématiques avec un accès par filtres");
    if (type === ProblemTypeEnum.PhotoProblem) setTitle("Des milliers de photo-problèmes mathématiques avec un accès par filtres");
  }, [type]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [filters]);

  const [editionBasket, setEditionBasket] = useState<EditionBasketProblem[]>([]);

  function handleSelected(problem: EditionBasketProblem) {
    var oldEditionBasket = [...editionBasket];
    const index = oldEditionBasket.findIndex((t) => t.id === problem.id);
    if (index === -1) {
      setEditionBasket((problems) => [
        ...problems,
        {
          id: problem.id,
          title: problem.title,
          attachment: problem.attachment,
          statement: problem.statement,
        },
      ]);
    } else {
      oldEditionBasket.splice(index, 1);
      setEditionBasket(oldEditionBasket);
    }
  }

  const editionMutation = useMutation<MutationData & { pdfData?: String; problems?: (TextProblemType | PhotoProblemType)[]; type?: number }, ApiError, { shuffle?: boolean }>({
    mutationFn: ({ shuffle = false }) => {
      const problemIds = editionBasket.map((problem) => {
        return problem.id;
      });
      return api.problem.pdf(editionType as EditionType, filters, problemIds, shuffle);
    },
    onSuccess: (data) => {
      if (editionType && editionType.editionType[0] === "pdf" && data.pdfData) {
        const linkSource = `data:application/pdf;base64,${data.pdfData}`;
        const downloadLink = document.createElement("a");
        document.body.appendChild(downloadLink);
        downloadLink.href = linkSource;
        downloadLink.download = "M@ths en-vie problèmes edition.pdf";
        downloadLink.click();
        document.body.removeChild(downloadLink);
      } else if (editionType && editionType.editionType[0] === "proj" && data.problems && data.type) {
        if (editionType.problemType === ProblemTypeEnum.TextProblem) {
          openModal(ModalTypeEnum.TextProblemProjection, { problems: data.problems as TextProblemType[], type: data.type });
        } else {
          openModal(ModalTypeEnum.PhotoProblemProjection, { problems: data.problems as PhotoProblemType[], type: data.type });
        }
      }
    },
  });

  if (isEdition && (editionType === null || editionType.problemType !== type)) {
    return (
      <>
        {type === ProblemTypeEnum.TextProblem && <EditionTextProblemType setEditionType={setEditionType} />}
        {type === ProblemTypeEnum.PhotoProblem && <EditionPhotoProblemType setEditionType={setEditionType} />}
      </>
    );
  }
  return (
    <div className="m-auto" style={{ maxWidth: 960 }}>
      {/* ===========================================
                Show warning if the user didn't contribuate
            ============================================ */}
      {data?.canSeePublicProblem === false && showAlert && !isEdition && (
        <Alert variant="info" dismissible onClose={() => setShowAlert(false)}>
          Pour pouvoir consulter l'intégralité de la banque collaborative de {transFromDiscr(type, true)}, vous devez{" "}
          {!user && (
            <>
              vous{" "}
              <Link to={Routes.APPS_REGISTER} className="alert-link">
                inscrire{" "}
              </Link>
              et{" "}
            </>
          )}
          <Link to={generatePath(Routes.CONTRIBUTE, { type: type })} className="alert-link">
            contribuer
          </Link>{" "}
          à au moins 3 {transFromDiscr(type, true)} ou saisir votre{" "}
          <Link to={Routes.APPS_PROFIL_PARAMETERS} className="alert-link">
            code adhérent.
          </Link>
        </Alert>
      )}
      {/* =========================================================
                Buttons at the top for filters, basket and pdf generation
            ========================================================== */}
      <div className="bg-white p-3 mb-2 pb-2 position-sticky z-4" style={{ top: 40 }}>
        {editionMutation.isError && (
          <Alert style={{ alignItems: "flex-end" }} variant={editionMutation.error.variant}>
            {editionMutation.error.message}
          </Alert>
        )}
        {isEdition && (
          <div className="d-flex flex-column flex-sm-row">
            <div style={{ flex: "1 0 0" }}></div>
            <div className="text-center" style={{ flex: "1 0 0" }}>
              <button
                className="btn btn-link text-decoration-none"
                onClick={() => {
                  editionMutation.reset();
                  setEditionType(null);
                }}
              >
                <FontAwesomeIcon icon={faRotate} /> Changer de format
              </button>
            </div>
            <div className="align-self-center d-flex justify-content-end" style={{ flex: "1 0 0" }}>
              {type === ProblemTypeEnum.TextProblem && <TraductionChoice />}
            </div>
          </div>
        )}
        <div className="d-flex justify-content-between">
          <div style={{ flex: "1 0 0" }} className="text-start">
            <button className="btn btn-primary" onClick={() => setShowFilter(true)}>
              Filtres <FontAwesomeIcon icon={faAngleDoubleRight} className="text-secondary" />
              {data && <span className="ms-2 badge bg-secondary text-black">{data.totalProblems}</span>}
            </button>
            {JSON.stringify(defaultFilters) !== JSON.stringify(filters) && filters && filters.problemType === type && (
              <OverlayTrigger overlay={<Tooltip id="reset-filters">Supprimer les filtres actifs.</Tooltip>}>
                <button className="d-inline-block btn btn-sm btn-danger py-0 ms-1" onClick={() => setFilters(defaultFilters)}>
                  X
                </button>
              </OverlayTrigger>
            )}
          </div>
          {!isEdition && type === ProblemTypeEnum.TextProblem && <TraductionChoice />}
          {isEdition && (
            <>
              <div className="text-center" style={{ flex: "1 0 0" }}>
                <div className="btn-group mx-1" role="group">
                  {editionType && editionType.editionType[0] === "proj" ? (
                    <button className="btn btn-primary" onClick={() => editionMutation.mutate({ shuffle: false })}>
                      <img className="d-inline-block align-text-top" src="img/whiteProjector.webp" alt="Icone projecteur" style={{ width: 30 }} /> Projeter{" "}
                      {editionMutation.isLoading && <FontAwesomeIcon className="ms-1" icon={faSpinner} spin />}
                    </button>
                  ) : (
                    <button className="btn btn-primary" onClick={() => editionMutation.mutate({ shuffle: false })}>
                      <FontAwesomeIcon icon={faFilePdf} /> Générer {editionMutation.isLoading && <FontAwesomeIcon className="ms-1" icon={faSpinner} spin />}
                    </button>
                  )}

                  <div className="btn-group" role="group">
                    <button className="btn btn-primary dropdown-toggle text-secondary" data-bs-toggle="dropdown" aria-expanded="false"></button>
                    <ul className="dropdown-menu dropdown-menu-end bg-secondary edition-dropdown">
                      <li>
                        <OverlayTrigger overlay={<Tooltip id="edition-selection">Le document sera généré à partir de votre sélection</Tooltip>}>
                          <button className="dropdown-item" onClick={() => editionMutation.mutate({ shuffle: false })}>
                            <FontAwesomeIcon icon={faListCheck} /> Sélection
                          </button>
                        </OverlayTrigger>
                      </li>
                      <li>
                        <OverlayTrigger overlay={<Tooltip id="edition-selection">L'application choisit aléatoirement parmis les {transFromDiscr(type, true)} disponibles</Tooltip>}>
                          <button className="dropdown-item" onClick={() => editionMutation.mutate({ shuffle: true })}>
                            <FontAwesomeIcon icon={faShuffle} /> Aléatoire
                          </button>
                        </OverlayTrigger>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="text-end" style={{ flex: "1 0 0" }}>
                <button className="btn btn-primary" onClick={() => setShowBasket(true)}>
                  Panier{" "}
                  <span className="badge text-bg-secondary text-black" id="basketBadge">
                    {editionBasket.length}
                  </span>
                </button>
              </div>
            </>
          )}
        </div>
      </div>
      <Offcanvas show={showFilter} onHide={() => setShowFilter(false)} style={{ width: 1050 }}>
        <Offcanvas.Body>
          {filters && filters.problemType === ProblemTypeEnum.TextProblem && (
            <TextProblemConsultForm isEdition={isEdition} setShowFilter={setShowFilter} setFilters={(filters: TextProblemConsultFormValues) => setFilters(filters)} filters={filters} />
          )}
          {filters && filters.problemType === ProblemTypeEnum.Photo && (
            <PhotoConsultForm setShowFilter={setShowFilter} setFilters={(filters: PhotoConsultFormValues) => setFilters(filters)} filters={filters} />
          )}
          {filters && filters.problemType === ProblemTypeEnum.PhotoProblem && (
            <PhotoProblemConsultForm isEdition={isEdition} setShowFilter={setShowFilter} setFilters={(filters: PhotoProblemConsultFormValues) => setFilters(filters)} filters={filters} />
          )}
        </Offcanvas.Body>
      </Offcanvas>
      {isEdition && (
        <Offcanvas show={showBasket} placement="end" onHide={() => setShowBasket(false)} style={{ width: 600 }}>
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Panier</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            {editionBasket.length !== 0 && (
              <div className="position-relative" style={{ height: 24 }}>
                <button className="btn-link btn position-absolute end-0 text-decoration-none p-0 border-0 me-2" onClick={() => setEditionBasket([])}>
                  Vider le panier
                </button>
              </div>
            )}
            <ul className="list-group list-group-basket">
              {editionBasket.map((problem) => (
                <li key={problem.id} className="list-group-item">
                  <button className="btn-close d-block float-end" onClick={() => handleSelected(problem)} aria-label="remove"></button>
                  {problem.title && <div className="fw-bold">{problem.title}</div>}
                  {problem.attachment && (
                    // eslint-disable-next-line jsx-a11y/img-redundant-alt
                    <img className="mw-50 h-auto align-middle" src={process.env.REACT_APP_BASE_API_URL + "/photos/" + problem.attachment} alt="Photo indisponible ..." />
                  )}
                  <div className="mt-2 text-break">{problem.statement}</div>
                </li>
              ))}
            </ul>
          </Offcanvas.Body>
        </Offcanvas>
      )}
      {/* ============================
          Displaying problems is pages
      ============================= */}
      {isLoading ? (
        <div aria-hidden="true" className="container-fluid">
          {type === ProblemTypeEnum.TextProblem && <TextProblemPlaceholder size={3} />}
          {type === ProblemTypeEnum.Photo && <PhotoPlaceholder size={4} />}
          {type === ProblemTypeEnum.PhotoProblem && <PhotoProblemPlaceholder size={4} />}
        </div>
      ) : isError ? (
        <Alert variant={error.variant}>{error.message}</Alert>
      ) : data.problems.length < 1 ? (
        <Alert variant="warning">
          {page === "1" ? (
            <>Nous n'avons trouvé aucun{transFromDiscr(type, true, true)} qui répondent à vos critères.</>
          ) : (
            <>
              Nous n'avons trouvé aucun{transFromDiscr(type, true, true)} sur cette page{" "}
              <Link className="alert-link" to={generatePath(Routes.CONSULT, { type: type, page: "1" })}>
                page n°1.
              </Link>
            </>
          )}
        </Alert>
      ) : (
        <>
          <ul className={"list-group" + (type !== ProblemTypeEnum.TextProblem ? " list-group-problem-photo" : "")}>
            {data.problems.map((problem) => (
              <li key={problem.id} className="list-group-item mb-2" style={{ borderRadius: 5, border: "1px solid lightgrey", boxShadow: "0px 0px 5px" }}>
                {problem.discr === ProblemTypeEnum.TextProblem && (
                  <TextProblem problem={problem} isEdition={isEdition} handleSelected={handleSelected} isSelected={editionBasket.find((t) => t.id === problem.id) !== undefined} />
                )}
                {problem.discr === ProblemTypeEnum.Photo && <Photo problem={problem} />}
                {problem.discr === ProblemTypeEnum.PhotoProblem && (
                  <PhotoProblem problem={problem} isEdition={isEdition} handleSelected={handleSelected} isSelected={editionBasket.find((t) => t.id === problem.id) !== undefined} />
                )}
              </li>
            ))}
          </ul>
          <Pagination page={Number.parseInt(page)} nbrPages={data.totalPage} nbrPagesInARow={5} url={regexPathname?.[1]} />
        </>
      )}
    </div>
  );
}

export default Consult;
