import sanitizeHtml from "sanitize-html";
import { EnigmaTypeEnum, PhotoTypeEnum, ProblemTypeEnum } from "../../Utils";

const br = (text: string) => {
  return text.replace(/\r\n|\r|\n/g, "<br>");
};

const transFromDiscr = (text: string, isPlural: boolean = false, isFeminine: boolean = false) => {
  switch (text) {
    case ProblemTypeEnum.TextProblem:
      return (isFeminine ? " " : "") + "problème" + (isPlural ? "s" : "");
    case ProblemTypeEnum.Photo:
      return (isFeminine ? "e " : "") + "photo" + (isPlural ? "s" : "");
    case ProblemTypeEnum.PhotoProblem:
      return (isFeminine ? " " : "") + "photo-problème" + (isPlural ? "s" : "");
    default:
      return "";
  }
};

const photoTypeToArray = (text: string): (keyof typeof PhotoTypeEnum)[] => {
  return text.split(";") as (keyof typeof PhotoTypeEnum)[];
};

const formatEnigmaType = (type: keyof typeof EnigmaTypeEnum) => {
  return EnigmaTypeEnum[type]
    .split(" ")
    .map((string) => string.replace(/s$/, ""))
    .join(" ");
};

const addBoldToQuestion = (text: string) => {
  return text.replace(
    /([A-Z0-9ÀÉ][^.?!]+?\?)|((Calcule|Calculate|Calcola|Calcula|Berechne|Indique|Indicate|Indica|Gib|Donne|Dai|Trouve|Find|Trova|Encuentra|Finde|Cherche|Cerca|Busca)[^.?!]+?\.)/g,
    function (question) {
      return '<span class="fw-bold">' + question + "</span>";
    }
  );
};

const defaultOptions: sanitizeHtml.IOptions = {
  allowedTags: ["br", "span"],
  allowedAttributes: {
    span: ["class"],
  },
};

const sanitize = (dirty: string) => ({
  __html: sanitizeHtml(dirty, defaultOptions),
});

const dateAgo = (date: number) => {
  // Js Time Stamp is in millisecondes
  const timeDifference = new Date().getTime() / 1000 - date;

  if (timeDifference < 1) {
    return "Il y a moins d'une seconde";
  }

  const condition: [secs: number, label: string][] = [
    [12 * 30 * 24 * 60 * 60, "an"],
    [30 * 24 * 60 * 60, "mois"],
    [24 * 60 * 60, "jour"],
    [60 * 60, "heure"],
    [60, "minute"],
    [1, "seconde"],
  ];

  for (const cond of condition) {
    const [secs, label] = cond;
    const d = timeDifference / secs;

    if (d >= 1) {
      const t = Math.floor(d);
      return "Il y a " + t + " " + label + (t > 1 && label !== "mois" ? "s" : "");
    }
  }

  return "Il y a moins d'une seconde";
};

const useUtils = () => {
  return {
    addBoldToQuestion,
    br,
    dateAgo,
    formatEnigmaType,
    photoTypeToArray,
    transFromDiscr,
    sanitize,
  };
};

export default useUtils;
