enum GenerateAiTypeEnum {
  Response = "response",
  HelpSentence1 = "helpSentence1",
  HelpSentence2 = "helpSentence2",
  ResponseExplication = "responseExplication",
  English = "english",
  Italian = "italian",
  Spanish = "spanish",
  German = "german",
  ALL = "all",
}

export default GenerateAiTypeEnum;
