import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuth } from "../contexts/auth";
import { faAngleDown, faAngleRight, faCartShopping, faCircleExclamation, faEllipsis, faEye, faFlag, faGauge, faMessage, faPen, faRefresh, faSquare, faTrash } from "@fortawesome/free-solid-svg-icons";
import { ApiError, useAPI, useUtils } from "../hooks";
import LikeButton from "./LikeButton";
import CopyButton from "./CopyButton";
import { GenerateAiTypeEnum, ModalTypeEnum, ResponseStateEnum, TextProblemTypeEnum } from "../Utils";
import { useModals } from "../contexts/modals";
import { useEffect, useRef, useState } from "react";
import { EditionBasketProblem, Lang, MutationData, TextProblemType } from "../types";
import { getOperators } from "../Utils/TextProblemTypeEnum";
import { Collapse, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useMutation, UseMutationResult, useQueryClient } from "@tanstack/react-query";
import { AdminGenerateAIData } from "../pages/Admin/query";
import { ProblemIdKey, ProblemsTypeKey } from "../pages/query";
import FormButtonLoading from "./FormButtonLoading";
import { useCookies } from "react-cookie";

type TextProblemProps = {
  problem: TextProblemType;
  isEdition?: boolean;
  handleSelected?: (problem: EditionBasketProblem) => void;
  isSelected?: boolean;
};

function TextProblem({ problem, isEdition = false, handleSelected, isSelected = false }: Readonly<TextProblemProps>) {
  const { user } = useAuth();
  const { addBoldToQuestion, br, sanitize } = useUtils();
  const { openModal } = useModals();
  const counterRef = useRef<HTMLSpanElement>(null);
  const [title, setTitle] = useState("");
  const [statement, setStatement] = useState("");

  const [cookies] = useCookies(["traduction-lang"]);

  useEffect(() => {
    const lang = cookies["traduction-lang"] as Lang | null;

    switch (lang) {
      case "english":
        setTitle(problem.traductions.english.title ?? "Waiting for translation ...");
        setStatement(problem.traductions.english.statement ?? "No translation is currently available for this problem.");
        break;
      case "italian":
        setTitle(problem.traductions.italian.title ?? "In attesa di una traduzione ...");
        setStatement(problem.traductions.italian.statement ?? "Al momento non è disponibile alcuna traduzione per questo problema.");
        break;
      case "spanish":
        setTitle(problem.traductions.spanish.title ?? "Esperando una traducción ...");
        setStatement(problem.traductions.spanish.statement ?? "Actualmente no hay traducción disponible para esta problema.");
        break;
      case "german":
        setTitle(problem.traductions.german.title ?? "Warten auf eine Übersetzung ...");
        setStatement(problem.traductions.german.statement ?? "Für diese Ausgabe ist derzeit keine Übersetzung verfügbar.");
        break;
      default:
        setTitle(problem.title);
        setStatement(problem.statement);
    }
  }, [cookies, problem.statement, problem.title, problem.traductions]);

  return (
    <div>
      <div className="text-break">
        <span style={{ fontWeight: 1000 }}>{title}</span>
        {user && (
          <div className="dropdown float-end">
            <span className="p-2" data-bs-toggle="dropdown" aria-expanded="false">
              <FontAwesomeIcon icon={faEllipsis} />
            </span>
            <ul className="dropdown-menu">
              {problem.canModify && (
                <li>
                  <button className="dropdown-item" onClick={() => openModal(ModalTypeEnum.ProblemModify, { problem: problem })}>
                    <FontAwesomeIcon icon={faPen} /> Modifier
                  </button>
                </li>
              )}
              {problem.canReport && (
                <li>
                  <button className="dropdown-item" onClick={() => openModal(ModalTypeEnum.ProblemReport, { problem: problem })}>
                    <FontAwesomeIcon icon={faFlag} /> Signaler
                  </button>
                </li>
              )}
              {problem.canPublish && (
                <li>
                  <button className="dropdown-item" onClick={() => openModal(ModalTypeEnum.ProblemPublish, { problem: problem })}>
                    <FontAwesomeIcon icon={faEye} /> Rendre public
                  </button>
                </li>
              )}
              {problem.canRemove && (
                <li>
                  <button className="dropdown-item text-danger" onClick={() => openModal(ModalTypeEnum.ProblemRemove, { problem: problem })}>
                    <FontAwesomeIcon icon={faTrash} /> Supprimer
                  </button>
                </li>
              )}
            </ul>
          </div>
        )}
      </div>
      <hr />
      <div className="text-break">
        <span dangerouslySetInnerHTML={sanitize(addBoldToQuestion(br(statement)))}></span>
      </div>
      <hr />
      <div className="text-center">
        <div className="row">
          <div className="col-md-5 col-12 text-start">
            {problem.successRate !== -1 && (
              <OverlayTrigger overlay={<Tooltip id="copy-button">Taux de réussite</Tooltip>}>
                <span className="badge text-bg-primary text-white me-1">
                  <FontAwesomeIcon icon={faGauge} className="me-1" />
                  {(problem.successRate * 100).toFixed(0)} %
                </span>
              </OverlayTrigger>
            )}
            <span className="badge text-bg-secondary text-black me-1">{problem.nivel.toUpperCase()}</span>
            <span className="badge text-bg-secondary text-black">{TextProblemTypeEnum[problem.type]}</span>
            {getOperators(TextProblemTypeEnum[problem.type]) && <span className="badge text-bg-secondary text-black ms-1">{getOperators(TextProblemTypeEnum[problem.type])}</span>}
          </div>
          <div className="col-md-2 col-12 text-center m-md-0 m-1">
            {isEdition && handleSelected ? (
              <button className={"btn btn-outline-primary btn-sm button-select-problem" + (isSelected ? " select-problem" : "")} onClick={() => handleSelected(problem)}>
                <FontAwesomeIcon icon={faCartShopping} /> Séléctionner
              </button>
            ) : (
              <CopyButton title={title} statement={statement} />
            )}
          </div>
          <div className="col-md-5 col-12 text-end">
            <span className="badge text-bg-primary text-light">
              <span>Auteur : {problem.username}</span>
              {problem.grade !== 0 && <span className="badge bg-secondary text-black ms-1">{problem.grade}+</span>}
            </span>

            <button className="btn btn-outline-ternary btn-sm mx-1" onClick={() => openModal(ModalTypeEnum.ProblemComments, { problem: problem, counterRef: counterRef })}>
              <FontAwesomeIcon icon={faMessage} /> <span ref={counterRef}>{problem.counterComment}</span>
            </button>

            <LikeButton isLiked={problem.isLiked} nbrLikes={problem.counterLike} problemId={problem.id} />
          </div>
        </div>
      </div>
      {user?.isAdmin && <ManageIa problem={problem} />}
    </div>
  );
}

type ManageIaProps = {
  problem: TextProblemType;
};

function ManageIa({ problem }: Readonly<ManageIaProps>) {
  const [collapseOpened, setCollapseOpened] = useState(false);
  const { openModal } = useModals();
  const api = useAPI();
  const queryClient = useQueryClient();

  const generateAiMutation = useMutation<MutationData & AdminGenerateAIData, ApiError, { problemId: number; type: GenerateAiTypeEnum }>({
    mutationFn: ({ problemId, type }) => {
      return api.admin.generateAI(problemId, type);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(ProblemsTypeKey(problem.discr));
      queryClient.invalidateQueries(ProblemIdKey(problem.id));
    },
  });

  return (
    <div>
      <hr />
      <div className="d-flex justify-content-between">
        <div className={problem.responseState === ResponseStateEnum.Verified ? "text-success" : ""}>
          Reponse : <span className="fw-bold">{problem.response ?? "..."}</span>
        </div>
        {problem.responseState === ResponseStateEnum.Conflict && (
          <button className="btn btn-danger btn-sm" onClick={() => openModal(ModalTypeEnum.ValidateResponse, { problem: problem })}>
            Résoudre le conflit <FontAwesomeIcon icon={faCircleExclamation} />
          </button>
        )}
        {problem.responseState === ResponseStateEnum.AnsweredByIa && (
          <button className="btn btn-warning btn-sm" onClick={() => openModal(ModalTypeEnum.ValidateResponse, { problem: problem })}>
            Confirmer la réponse <FontAwesomeIcon icon={faCircleExclamation} />
          </button>
        )}
        <div>{problem.isExclude ? <FontAwesomeIcon className="text-danger" size="xl" icon={faSquare} /> : <FontAwesomeIcon className="text-success" size="xl" icon={faSquare} />}</div>
      </div>
      {!problem.isExclude && (
        <div>
          <div className="text-start">
            <button className="btn btn-sm p-0" onClick={() => setCollapseOpened(!collapseOpened)} aria-controls="collapse-ia" aria-expanded={collapseOpened}>
              <FontAwesomeIcon size="xl" icon={collapseOpened ? faAngleDown : faAngleRight} />
            </button>
          </div>
          <Collapse in={collapseOpened}>
            <div id="collapse-ia">
              <div className="mb-3">
                <ReGenerateIAButton mutation={generateAiMutation} problemId={problem.id} type={GenerateAiTypeEnum.Response} />
                <span className="fw-bold">Phrase réponse : </span>
                {problem.responseSentence}
              </div>
              <div className="mb-3">
                <ReGenerateIAButton mutation={generateAiMutation} problemId={problem.id} type={GenerateAiTypeEnum.ResponseExplication} />
                <span className="fw-bold">Explication solution : </span>
                {problem.responseExplication}
              </div>
              <div className="mb-3">
                <ReGenerateIAButton mutation={generateAiMutation} problemId={problem.id} type={GenerateAiTypeEnum.HelpSentence1} />
                <span className="fw-bold">Aide de niveau 1 : </span> {problem.helpSentence1}
              </div>
              <div className="mb-3">
                <ReGenerateIAButton mutation={generateAiMutation} problemId={problem.id} type={GenerateAiTypeEnum.HelpSentence2} />
                <span className="fw-bold">Aide de niveau 2 : </span>
                {problem.helpSentence2}
              </div>
              <div className="text-center">
                <ReGenerateIAButton mutation={generateAiMutation} problemId={problem.id} type={GenerateAiTypeEnum.ALL} label="Tout générer" />
              </div>
            </div>
          </Collapse>
        </div>
      )}
    </div>
  );
}

type ReGenerateIAButtonProps = {
  mutation: UseMutationResult<
    MutationData & AdminGenerateAIData,
    ApiError,
    {
      problemId: number;
      type: GenerateAiTypeEnum;
    }
  >;
  problemId: number;
  type: GenerateAiTypeEnum;
  label?: string;
};

function ReGenerateIAButton({ mutation, problemId, type, label = "" }: Readonly<ReGenerateIAButtonProps>) {
  return (
    <FormButtonLoading
      className="btn btn-sm btn-primary px-1 py-0 me-1"
      isLoading={mutation.isLoading && (mutation.variables?.type === type || mutation.variables?.type === GenerateAiTypeEnum.ALL)}
      label={label}
      icone={faRefresh}
      onClick={() => mutation.mutate({ problemId: problemId, type: type })}
    />
  );
}

export default TextProblem;
