import { generatePath, useNavigate, useParams } from "react-router-dom";
import { ApiError, useAPI, useDocumentTitle } from "../hooks";
import { useQuery } from "@tanstack/react-query";
import { Photo, PhotoProblem, TextProblem, TextProblemPlaceholder } from "../components";
import { ProblemTypeEnum } from "../Utils";
import * as Routes from "../routes";
import { ProblemType } from "../types";
import { ProblemIdKey } from "./query";
import { Alert } from "react-bootstrap";
import { useEffect } from "react";
import TraductionChoice from "../components/TraductionChoice";

type ParamsType = {
  id: string;
};

function Problem() {
  useDocumentTitle();
  const { id } = useParams<ParamsType>() as ParamsType;
  const api = useAPI();
  const navigate = useNavigate();

  const {
    isLoading,
    isError,
    error,
    data: problem,
  } = useQuery<ProblemType, ApiError>(ProblemIdKey(Number.parseInt(id)), () => api.problem.getProblem(Number.parseInt(id)), {
    staleTime: 60 * 1000, // 1 min
  });

  useEffect(() => {
    if (error?.code === 403 || error?.code === 404) {
      navigate(generatePath(Routes.CONSULT, { type: ProblemTypeEnum.TextProblem, page: "1" }));
    }
  });

  return (
    <div className="m-auto" style={{ maxWidth: 960 }}>
      {isLoading ? (
        <div aria-hidden="true" className="container-fluid">
          <TextProblemPlaceholder size={1} />
        </div>
      ) : isError ? (
        <Alert variant={error.variant}>{error.message}</Alert>
      ) : (
        <>
          {problem.discr === ProblemTypeEnum.TextProblem && (
            <div className="mb-3 d-flex justify-content-end me-3">
              <TraductionChoice />
            </div>
          )}
          <div className="m-auto border p-3" style={{ maxWidth: 960, borderRadius: 5, border: "1px solid lightgrey", boxShadow: "0px 0px 5px" }}>
            {problem.discr === ProblemTypeEnum.TextProblem && <TextProblem problem={problem} />}
            {problem.discr === ProblemTypeEnum.Photo && <Photo problem={problem} />}
            {problem.discr === ProblemTypeEnum.PhotoProblem && <PhotoProblem problem={problem} />}
          </div>
        </>
      )}
    </div>
  );
}

export default Problem;
